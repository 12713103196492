import { Drawer, DrawerOverlay, DrawerProps, useDisclosure } from '@chakra-ui/react';
import * as React from 'react';
import { useState } from 'react';

export const useDrawer = <Props,>({
  Component,
  drawerProps,
}: {
  Component: React.FC<Props>;
  drawerProps?: Omit<DrawerProps, 'isOpen' | 'onClose' | 'children'>;
}): {
  open: (props: Props) => void;
  close: () => void;
  element: React.ReactElement;
} => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [contentProps, setContentProps] = useState<Props | null>(null);

  return {
    open: (props) => {
      setContentProps(props);
      onOpen();
    },
    close: () => {
      onClose();
    },
    element: (
      <Drawer
        size={{ base: 'full', md: 'lg' }}
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        autoFocus={false}
        {...drawerProps}
      >
        {contentProps && (
          <>
            <DrawerOverlay />
            <Component {...contentProps} />
          </>
        )}
      </Drawer>
    ),
  };
};
